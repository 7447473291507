import React from "react"
import LegalNotices from "../components/pages/LegalNotices"
import { graphql } from "gatsby"

const PrivacyPolicy = ({ data, pageContext }) => {
  const { seoDataJson } = data
  const seoData = {
    url: seoDataJson.url_en,
    titleSeo: seoDataJson.title_en,
    description: seoDataJson.description_en,
    alternateLanguage: seoDataJson.alternateLanguage_en,
    alternateUrl: seoDataJson.alternateUrl_en,
  }
  const lang = "en"

  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === "LEGAL"
  })

  return (
    <LegalNotices lang={lang} seoData={seoData} urls={resultUrls} />
  )
}

export const pageQuery = graphql`
  query legalNoticesEnQuery {
    seoDataJson(name: { eq: "legal" }) {
      url_en
      title_en
      description_en
      alternateLanguage_en
      alternateUrl_en
    }
  }
`

export default PrivacyPolicy
