import React from "react"
import Layout from "../../Layout/Layout"
import styles from "../../../styles/privacy.module.scss"
import tt from "../../../helpers/translation"

const LegalNotices = ({ lang, seoData, urls }) => {
  return (
    <Layout
      lan={lang}
      contrast={true}
      seoData={seoData}
      urls={urls}
      noFooter
      location={"legal"}
    >
      <div className={styles.container}>
        <div>
          <div className={`${styles.text_container} ${styles.title}`}>
            <h1>{tt("Legal Notices", lang).toUpperCase()}</h1>
            <div className={styles.subtitle_p_container}>
              <p className={styles.subtitle}>
                {tt("LEGAL NOTICE AND PRIVACY POLICY", lang)}
              </p>
            </div>
          </div>

          <div className={styles.text_container}>
            <h3>{tt("IDENTIFICATION", lang)}</h3>
            <p className={styles.description}>
              {tt(
                "In compliance with the duty of information contained in article 10 of Law 34/2002, of July 11, on Services of the Information Society and Electronic Commerce, INTERFICIE PROYECTOS INTERACTIVOS S.L.U, informs that the data contained herein correspond to the entity that owns the website www.interficie.com.",
                lang
              )}
            </p>
            <p className={styles.description}>
              <b>{tt("Name: ", lang)}</b>
              <span>INTERFICIE PROYECTOS INTERACTIVOS S.L.U</span>
              <br></br>
              <b>{tt("Address: ", lang)}</b>
              <span>C/ Roger de Llúria, 118 3er 1a - 08037 BARCELONA</span>
              <br></br>
              <b>{tt("Telephone: ", lang)}</b>
              <span>932 688 450</span>
              <br></br>
              <b>{tt("Email: ", lang)}</b>
              <span>lopd@interficie.com</span>
              <br></br>
              <b>{tt("CIF: ", lang)}</b>
              <span>B62523022</span>
              <br></br>
              <b>{tt("Registration: ", lang)}</b>
              <span>
                {tt(
                  "Registered in the Mercantile Registry of Barcelona, ​​in Volume 33409, Folio 117, Sheet nº 225396",
                  lang
                )}
              </span>
            </p>
          </div>
          <div className={styles.text_container}>
            <h3>{tt("INTELLECTUAL AND INDUSTRIAL PROPERTY", lang)}</h3>
            <p className={styles.description}>
              {tt(
                "All the Industrial and Intellectual Property rights of all the elements contained in this Web, including the trademarks (except for the brands of clients and suppliers), formats, graphic designs, texts, images and documents, belong to INTERFICIE PROYECTOS INTERACTIVOS SLU and are protected by Spanish and international laws on Intellectual and Industrial property.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "It is expressly prohibited the total or partial reproduction of this website and any of its contained without the express written permission of INTERFICIE PROYECTOS INTERACTIVOS S.L.U.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "Access to the website does not imply any type of waiver, transmission, license or assignment of said rights by INTERFICIE PROYECTOS INTERACTIVOS S.L.U, unless expressly stated otherwise.",
                lang
              )}
            </p>
          </div>
          <div className={styles.text_container}>
            <h3>{tt("TERMS OF USE", lang)}</h3>
            <p className={styles.description}>
              {tt(
                "Access to this website implies acceptance of these conditions of use without reservation that regulate access and use of the same in order to make information about our products available to users.",
                lang
              )}
              <br></br>
              {tt(
                "The use of the contents of this website for its use for commercial purposes or for its distribution, transformation or communication is expressly prohibited.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "INTERFICIE PROYECTOS INTERACTIVOS S.L.U, will not be liable for any consequence, damage or harm that may arise from said use or use of the information.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "Both access to this website and the use that may be made of the information contained therein is the sole responsibility of the user.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "The user agrees not to use the information published on this website for illicit or harmful purposes or effects, not to damage or disable the information and not to take any other action that may be contrary to the content of this Legal Notice.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "INTERFICIE PROYECTOS INTERACTIVOS S.L.U cannot guarantee the absence of interruptions or errors in the access to this website, although will do his best to avoid them.",
                lang
              )}
            </p>
          </div>
          <div className={styles.text_container}>
            <h3>{tt("DATA PROTECTION POLICY", lang)}</h3>
            <p className={styles.description}>
              {tt(
                "In compliance with REGULATION (EU) 2016/679 OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL of April 27, 2016, regarding the protection of natural persons with regard to the processing of personal data and the free movement of this data and its regulations of development, you are informed that the personal data provided by you will be treated for the following purposes:",
                lang
              )}
            </p>
            <ul>
              <li>
                {tt(
                  "Inform you about our products and services, when requested.",
                  lang
                )}
              </li>
              <li>
                {tt(
                  "Answer and manage your queries, comments and suggestions, if applicable.",
                  lang
                )}
              </li>
              <li>
                {tt(
                  "Manage your participation in present and future selection processes, when you send us your data (including your CV) for this purpose. When we start a selection process where your profile can fit, we will contact you. We will keep your personal data for a period of two years in order to be able to count on your participation in future processes.",
                  lang
                )}
              </li>
            </ul>
            <p className={styles.description}>
              {tt(
                "Through the completion of contact forms on the Web or by sending emails or any other type of information request sent to INTERFICIE PROYECTOS INTERACTIVOS S.L.U, you give your express consent for the processing of your personal data.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "In no case will INTERFICIE PROYECTOS INTERACTIVOS S.L.U use the personal data of the interested parties for purposes other than those mentioned above, nor will it communicate it to third parties without the prior and express consent of the affected, and undertakes to keep due professional secrecy and to establish the necessary technical and organizational measures to safeguard the information in accordance with the requirements established by said Regulation.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "The legal basis for the treatment of contact data obtained through this website for the purposes indicated above will be the express consent given by the interested party.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "Also, when the user requests our services, the legal basis of the treatment will be the fulfillment of a contract of which the interested party is a part.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "The personal contact information provided through this website will be kept in our records for a period of three years. In relation to the data processed for the provision of our services, these will be kept as long as necessary to achieve this end and responsibilities may arise from their treatment.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "However, at any time you can request in writing accompanied by a copy of an official document that identifies you, access to your personal data and its rectification or deletion. As well as, limit their treatment, or directly oppose the treatment or exercise the right to their portability.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "We also inform you of your right to file a claim with the Spanish Data Protection Agency, if you consider that your rights are not being respected in the processing of your data.",
                lang
              )}
            </p>
          </div>
          <div className={styles.text_container}>
            <h3>{tt("CHILD PROTECTION POLICY", lang)}</h3>
            <p className={styles.description}>
              {tt(
                "Whoever provides the data through the forms on this website and accepts their treatment formally declares to be over 14 years of age.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "Access and use of the portal is prohibited for minors under 14 years of age.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "INTERFICIE PROYECTOS INTERACTIVOS S.L.U reminds people of legal age who are in charge of minors, that it will be their sole responsibility if any minor enters their data to request a product.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "It also informs them that there are computer programs to limit navigation by filtering or blocking certain content.",
                lang
              )}
            </p>
          </div>
          <div className={styles.text_container}>
            <h3>{tt("SOCIAL NETWORKS", lang)}</h3>
            <p className={styles.description}>
              {tt(
                "By following our profile on social networks, you expressly consent to the processing of your personal data in accordance with its privacy policy. You also expressly consent to the access of INTERFICIE PROYECTOS INTERACTIVOS S.L.U to the processing of your data contained in the friends list and that the news published on any product of INTERFICIE PROYECTOS INTERACTIVOS S.L.U appear on your wall.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "In accordance with REGULATION (EU) 2016/679 OF THE EUROPEAN PARLIAMENT, we inform you that the personal data of the followers of INTERFICIE PROYECTOS INTERACTIVOS S.L.U, will be incorporated into a file whose The owner is INTERFICIE PROYECTOS INTERACTIVOS S.L.U in order to keep you informed through social networks about news related to INTERFICIE PROYECTOS INTERACTIVOS S.L.U.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "Your request to connect necessarily implies your consent to the indicated treatments. The publication of comments and content on social networks will become public information, so users must be especially cautious when they decide to share their personal information. INTERFACE PROJECTS INTERACTIVOS S.L.U is not responsible for the information that users enter on the page. However, people whose personal data is published or included in comments, may request INTERFICIE PROYECTOS INTERACTIVOS S.L.U to cancel them.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "At any time, you can exercise the rights of access, rectification, deletion and opposition, limit the treatment of your data, or directly oppose the treatment, or exercise the right to the portability of the same. All this, in writing, accompanied by a copy of an official document that identifies you, addressed to INTERFICIE PROYECTOS INTERACTIVOS S.L.U. In case of disagreement with the treatment, you also have the right to file a claim with the Spanish Agency for Data Protection.",
                lang
              )}
            </p>
          </div>
          <div className={styles.text_container}>
            <h3>{tt("LINKS TO OTHER WEBSITES", lang)}</h3>
            <p className={styles.description}>
              {tt(
                "The links that you can find on this website are a service to users. These pages are not operated or controlled by INTERFICIE PROYECTOS INTERACTIVOS S.L.U, therefore, it is not responsible for the contents of Those websites are not covered by this Legal Notice. If you access these web pages, you must take into account that their privacy policies may be different from ours.",
                lang
              )}
            </p>
          </div>
          <div className={styles.text_container}>
            <h3>
              {tt("APPLICABLE LEGISLATION AND JURISDICTIONAL COMPETENCE", lang)}
            </h3>
            <p className={styles.description}>
              {tt(
                "This Legal Notice is governed by current Spanish regulations that apply to it. For the resolution of controversies that may arise as a result of the provisions of these provisions and their interpretation, application and compliance, the user, by virtue of the acceptance of the conditions contained in this Legal Notice, expressly waives any other jurisdiction that may correspond.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                "In any case, within Spanish jurisdiction, if the legislation allows you to submit to a specific jurisdiction, the user expressly waives any jurisdiction that may correspond to him and voluntarily submits to the jurisdiction of the Courts and Tribunals of BARCELONA.",
                lang
              )}
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default LegalNotices
